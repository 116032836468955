function onYouglishAPIReady() {
  // all the function can be overwritten youglish-modal.component.ts
  widget = new YG.Widget('widget-1', {
    width: 400,
    components: 9, //search box & caption
    events: {
      onFetchDone: onFetchDone,
      onVideoChange: onVideoChange,
      onCaptionConsumed: onCaptionConsumed,
    },
  });
  // 4. process the query
  // widget.fetch('courage', 'english');
};

var views = 0,
  curTrack = 0,
  totalTracks = 0;

// 5. The API will call this method when the search is done
function onFetchDone(event) {
  console.log('onFetchDone');
  if (event.totalResult === 0) alert('No result found');
  else totalTracks = event.totalResult;
}

// 6. The API will call this method when switching to a new video.
function onVideoChange(event) {
  console.log('onVideoChange');
  curTrack = event.trackNumber;
  views = 0;
}

// 7. The API will call this method when a caption is consumed.
function onCaptionConsumed(event) {
  // overwritten in modal
  console.log('onCaptionConsumed');
  if (++views > 2) widget.replay();
  else if (curTrack < totalTracks) widget.next();
}
